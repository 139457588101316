/* global _ */
import WorkflowProductionEntityTransitionController from '../dialogs/workflow.production-entity-transition.controller'

const UUID = require('uuid')

/** @ngInject */
function productionEntityService (
  $mdDialog,
  ProductionEntity,
  ViewsService,
  KendoGridHelper,
  $translate,
  $rootScope,
  htmlWork
) {
  const vm = {}
  vm.inProgress = false
  vm.initialized = false

  vm.transitionHandlerProductionEntity =
    function transitionHandlerProductionEntity (sourceMode, targetMode, id) {
      let items = []
      if (id) {
        items = [vm.dataByTableName[sourceMode].find(item => item.id === id)]
      } else {
        const selectedItemIds = vm.selectedByTableName[sourceMode].map(
          item => item.id
        )
        items = vm.dataByTableName[sourceMode].filter(item =>
          selectedItemIds.includes(item.id)
        )
      }
      if (items.length > 0) {
        if (
          !items.every(item => !item.isSerial || (item.isSerial && item.serial))
        ) {
          $rootScope.showErrorToast(
            $translate.instant(
              'WF.WORKFLOW_PRODUCTION_ENTITY.ERRORS.SERIAL_REQUIRED'
            )
          )
          return
        }
        $mdDialog
          .show({
            controller: WorkflowProductionEntityTransitionController,
            template: require('../dialogs/workflow.production-entity-transition.template.html'),
            multiple: true,
            locals: {
              sourceMode,
              targetMode,
              items,
              availableProductionEntities: vm.availableProductionEntities
            }
          })
          .then(manipulatedItems => {
            if (
              !vm.fromProductionEntity &&
              sourceMode === 'checkedOut' &&
              targetMode === 'pending'
            ) {
              const itemsBySessionId = _.groupBy(manipulatedItems, 'sessionId')
              const itemsCannotBeReverted = []
              vm.sessions.forEach(session => {
                if (itemsBySessionId[session.id]?.length > 0) {
                  const sessionNodeId =
                    itemsBySessionId[session.id].length > 0
                      ? itemsBySessionId[session.id][0].sessionNodeId
                      : null
                  if (sessionNodeId) {
                    const sessionNode = session.sessionNodes.get(sessionNodeId)
                    if (sessionNode?.status === 'done') {
                      itemsCannotBeReverted.push(
                        ...itemsBySessionId[session.id]
                      )
                    }
                  }
                }
              })
              if (itemsCannotBeReverted.length > 0) {
                $rootScope.showErrorToast(
                  $translate.instant(
                    'WF.WORKFLOW_PRODUCTION_ENTITY.ERRORS.COULD_NOT_REVERT_CHECKED_OUT_ITEMS_ON_SIGNED_STEP',
                    {
                      items: itemsCannotBeReverted
                        .map(item => item.serial || item.displayName)
                        .join(', ')
                    }
                  )
                )
                return
              }
            }
            ProductionEntity.handleTransition({
              items: manipulatedItems,
              sourceMode,
              targetMode
            })
              .$promise.then(result => {
                if (result.success) {
                  vm.refreshTablesData()
                }
              })
              .catch(error => {
                console.error('error', error)
                let message = $translate.instant(
                  'WF.WORKFLOW_PRODUCTION_ENTITY.ERRORS.DEFAULT'
                )
                if (
                  [
                    'SESSION_ID_REQUIRED',
                    'SERIAL_REQUIRED',
                    'STOCK_ID_REQUIRED',
                    'PRODUCTION_ENTITY_ID_REQUIRED',
                    'QUANTITY_REQUIRED',
                    'SOURCE_MODE_REQUIRED',
                    'TARGET_MODE_REQUIRED',
                    'SESSION_NOT_FOUND',
                    'STOCK_NOT_FOUND',
                    'NOT_ENOUGH_PENDING_QUANTITY',
                    'NOT_ENOUGH_CHECKED_IN_QUANTITY',
                    'NOT_ENOUGH_CHECKED_OUT_QUANTITY'
                  ].includes(error.data.error.code)
                ) {
                  message = $translate.instant(
                    `WF.WORKFLOW_PRODUCTION_ENTITY.ERRORS.${error.data.error.code}`
                  )
                }
                $rootScope.showErrorToast(message)
              })
          })
      } else {
        $rootScope.showErrorToast(
          $translate.instant(
            'WF.WORKFLOW_PRODUCTION_ENTITY.ERRORS.NO_ITEMS_SELECTED'
          )
        )
      }
    }
  const createProductionEntityItems =
    async function createProductionEntityItems () {
      const items = []
      const filter = {
        or: []
      }
      vm.sessions.forEach(session => {
        const realSession = session.session ? session.session : session
        const workorder = realSession.workorder || null
        const displayName = realSession.name
          ? `${realSession.indicator} (${realSession.name})`
          : realSession.indicator
        const isSerial =
          (workorder && workorder.isSerial) ||
          (!workorder && realSession.sessionPart?.isSerial)
        const sku = workorder?.sku ? workorder.sku : realSession.partSku || null
        let sessionNodeId = null
        if (vm.fromProductionEntity) {
          sessionNodeId = realSession.sessionNodeId
        } else {
          sessionNodeId = session.currentSessionNodeId
        }
        if (workorder?.createStock) {
          realSession.stocks.forEach(stock => {
            items.push({
              workorderId: workorder?.id || null,
              workorderNumber: workorder?.workorderNumber || null,
              sku,
              sessionNodeId,
              nextSessionNodeId: realSession.nextSessionNodeId,
              stockId: stock.id,
              sessionId: realSession.id,
              serial: stock.serial,
              quantity: stock.quantity,
              isSerial: stock.isSerial,
              displayName
            })
            filter.or.push({
              and: [
                { sessionId: realSession.id },
                { stockId: stock.id },
                { sessionNodeId }
              ]
            })
          })
        } else {
          if (isSerial) {
            for (let i = 0; i < realSession.quantity; i++) {
              const stock = realSession.stocks[i]
              if (stock) {
                items.push({
                  workorderId: workorder?.id || null,
                  workorderNumber: workorder?.workorderNumber || null,
                  sku,
                  sessionNodeId,
                  nextSessionNodeId: realSession.nextSessionNodeId,
                  stockId: stock.id,
                  sessionId: realSession.id,
                  serial: stock.serial,
                  quantity: stock.quantity,
                  isSerial: stock.isSerial,
                  displayName
                })
                filter.or.push({
                  and: [
                    { sessionId: realSession.id },
                    { stockId: stock.id },
                    { sessionNodeId }
                  ]
                })
              } else {
                items.push({
                  workorderId: workorder?.id || null,
                  workorderNumber: workorder?.workorderNumber || null,
                  sku,
                  sessionNodeId,
                  nextSessionNodeId: realSession.nextSessionNodeId,
                  stockId: null,
                  sessionId: realSession.id,
                  serial: null,
                  quantity: 1,
                  isSerial: true,
                  displayName
                })
              }
            }
          } else {
            items.push({
              workorderId: workorder?.id || null,
              workorderNumber: workorder?.workorderNumber || null,
              sku,
              sessionNodeId,
              nextSessionNodeId: realSession.nextSessionNodeId,
              stockId: null,
              sessionId: realSession.id,
              serial: null,
              quantity: realSession.quantity,
              isSerial: false,
              displayName
            })
            filter.or.push({
              and: [{ sessionId: realSession.id }, { sessionNodeId }]
            })
          }
        }
      })
      let relatedMappings = []
      if (filter.or.length > 0) {
        relatedMappings = await ProductionEntity.getStockRelatedMapping({
          filter
        }).$promise
      }
      const mappingsBySessionId = _.groupBy(relatedMappings, 'sessionId')
      const mappingsByStockId = _.groupBy(relatedMappings, 'stockId')
      vm.productionEntityItems = []
      for (const item of items) {
        let mappings = []
        if (item.stockId) {
          mappings = mappingsByStockId[item.stockId] || []
        } else {
          mappings = mappingsBySessionId[item.sessionId] || []
        }
        if (mappings.length > 0) {
          mappings = mappings.filter(
            mapping => mapping.sessionNodeId === item.sessionNodeId
          )
        }
        const mappingsByProductionEntityId = _.groupBy(
          mappings,
          'productionEntityId'
        )
        let itemQuantity = item.quantity
        const productionEntities = []
        for (const productionEntityId in mappingsByProductionEntityId) {
          const productionEntityMappings =
            mappingsByProductionEntityId[productionEntityId]
          const checkedInQty = _.sumBy(productionEntityMappings, 'checkedInQty')
          const checkedOutQty = _.sumBy(
            productionEntityMappings,
            'checkedOutQty'
          )

          productionEntities.push({
            id: UUID(),
            productionEntityId,
            checkedInQty,
            checkedOutQty
          })
          itemQuantity -= checkedInQty + checkedOutQty
        }
        if (
          itemQuantity > 0 &&
          (!vm.fromProductionEntity ||
            (vm.fromProductionEntity &&
              item.nextSessionNodeId === item.sessionNodeId))
        ) {
          productionEntities.push({
            id: UUID(),
            productionEntityId: null,
            pendingQty: itemQuantity,
            checkedInQty: 0,
            checkedOutQty: 0
          })
        }
        vm.productionEntityItems.push({
          ...item,
          productionEntities
        })
      }
    }
  const getProductionEntityTableColumns =
    function getProductionEntityTableColumns (tableName = 'pending') {
      const tableColumns = []
      if (vm.fromProductionEntity) {
        // WORKORDER_NUMBER
        tableColumns.push({
          uniqueId: '552a5791-506a-45f9-8cf6-65b17254dee8',
          field: 'workorderNumber',
          resizable: false,
          translateCode:
            'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.WORKORDER_NUMBER',
          trustedTemplate: data => {
            return data.workorderNumber
              ? `<a ui-sref="app.workorder.show({id: '${htmlWork.htmlEncode(
                  data.workorderId
                )}'})" target="_blank">${htmlWork.htmlEncode(
                  data.workorderNumber
                )}</a>`
              : '--'
          }
        })
      }
      tableColumns.push(
        // DISPLAY_NAME
        {
          uniqueId: 'b3456789-0123-4567-8901-234567890123',
          field: 'displayName',
          resizable: false,
          translateCode:
            'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.INDICATOR_AND_NAME',
          trustedTemplate: data => {
            return data.displayName
              ? `<a ui-sref="app.workflow.session.show({workflowSessionIds: ['${htmlWork.htmlEncode(
                  data.sessionId
                )}'], preview: false})" target="_blank">${htmlWork.htmlEncode(
                  data.displayName
                )}</a>`
              : '--'
          }
        }
      )
      if (vm.productionEntityItems.some(item => item.isSerial)) {
        tableColumns.push(
          // SERIAL
          {
            uniqueId: 'a5240dec-7f12-41e6-80a8-4f5527452d3d',
            field: 'serial',
            resizable: false,
            translateCode: 'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.SERIAL'
          }
        )
      }
      const quantityField =
        tableName === 'pending'
          ? 'pendingQty'
          : tableName === 'checkedIn'
          ? 'checkedInQty'
          : 'checkedOutQty'
      tableColumns.push(
        // QUANTITY
        {
          uniqueId: 'bd4b1ff8-48d0-481f-ae66-a842880b91ff',
          field: quantityField,
          resizable: false,
          translateCode: 'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.QUANTITY'
        }
      )
      if (tableName !== 'pending') {
        tableColumns.push(
          // PRODUCTION_ENTITY
          {
            uniqueId: '43237be2-1791-4c8f-92a6-b05ec0f7e844',
            field: 'productionEntityId',
            resizable: false,
            translateCode:
              'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.PRODUCTION_ENTITY',
            trustedTemplate: data => {
              return `<span ng-bind="productionEntityFactory.availableProductionEntitiesById['${data.productionEntityId}'].name"></span>`
            }
          }
        )
      }
      tableColumns.push(
        // ACTIONS
        {
          uniqueId: '04f84867-1a23-4841-b6b2-54040fc687d0',
          field: 'actions',
          filterable: false,
          sortable: false,
          translateCode: 'WF.WORKFLOW_PRODUCTION_ENTITY.COLUMNS.ACTIONS',
          trustedTemplate: data => {
            let html = `<div layout="column">
                      <div layout="row" layout-align="start center">`
            if (tableName === 'pending') {
              html += `<md-button class="md-primary md-raised" ng-click="productionEntityFactory.transitionHandlerProductionEntity('pending','checkedIn','${data.id}')"><span translate="WF.WORKFLOW_PRODUCTION_ENTITY.BUTTONS.CHECK_IN"></span></md-button>`
            } else if (tableName === 'checkedIn') {
              html += `<md-button class="md-primary md-raised" ng-click="productionEntityFactory.transitionHandlerProductionEntity('checkedIn','checkedOut','${data.id}')"><span translate="WF.WORKFLOW_PRODUCTION_ENTITY.BUTTONS.CHECK_OUT"></span></md-button>`
              html += `<md-button class="md-primary md-raised" ng-click="productionEntityFactory.transitionHandlerProductionEntity('checkedIn','pending','${data.id}')"><span translate="WF.WORKFLOW_PRODUCTION_ENTITY.BUTTONS.RETURN_TO_PENDING"></span></md-button>`
            } else if (tableName === 'checkedOut') {
              html += `<md-button class="md-primary md-raised" ng-click="productionEntityFactory.transitionHandlerProductionEntity('checkedOut','pending','${data.id}')"><span translate="WF.WORKFLOW_PRODUCTION_ENTITY.BUTTONS.RETURN_TO_PENDING"></span></md-button>`
            }
            html += `</div></div>`
            return html
          }
        }
      )
      return tableColumns
    }

  const manipulateData = function manipulateData () {
    vm.dataByTableName = {
      pending: [],
      checkedIn: [],
      checkedOut: []
    }
    vm.productionEntityItems.forEach(item => {
      Object.values(item.productionEntities).forEach(productionEntity => {
        if (productionEntity.pendingQty > 0) {
          vm.dataByTableName.pending.push({
            ...item,
            ...productionEntity
          })
        }
        if (productionEntity.checkedInQty > 0) {
          vm.dataByTableName.checkedIn.push({
            ...item,
            ...productionEntity
          })
        }
        if (productionEntity.checkedOutQty > 0) {
          vm.dataByTableName.checkedOut.push({
            ...item,
            ...productionEntity
          })
        }
      })
    })
  }

  const selectionChanged = function selectionChanged (tableName, selectedItems) {
    vm.selectedByTableName[tableName] = selectedItems
  }

  vm.allItemsCheckedOut = function allItemsCheckedOut () {
    return (
      vm.productionEntityItems?.length > 0 &&
      vm.productionEntityItems.every(
        item =>
          item.quantity === _.sumBy(item.productionEntities, 'checkedOutQty')
      )
    )
  }

  vm.init = async function init (
    $scope,
    sessions,
    availableProductionEntities,
    fromProductionEntity = false
  ) {
    vm.inProgress = true
    console.log(
      '%c **************************** Production entity service initialization ****************************',
      'background: #039be5 ; color: #ffffff'
    )
    vm.sessions = sessions
    vm.availableProductionEntities = availableProductionEntities
    vm.availableProductionEntitiesById = _.keyBy(
      vm.availableProductionEntities,
      'id'
    )
    vm.fromProductionEntity = fromProductionEntity
    const tableNames = ['pending', 'checkedIn']
    if (!vm.fromProductionEntity) {
      tableNames.push('checkedOut')
    }
    vm.refreshTablesData = async function refreshTablesData () {
      await createProductionEntityItems()
      manipulateData()

      for (const tableName of tableNames) {
        if ($scope[`${tableName}KendoGrid`]?.clearSelection) {
          $scope[`${tableName}KendoGrid`].clearSelection()
        }
        $scope[`${tableName}KendoGrid`].instance.dataSource.data(
          vm.dataByTableName[tableName]
        )
        $scope.$applyAsync()
      }
      vm.inProgress = false
    }
    vm.destroy = function destroy () {
      vm.productionEntityItemsById = {}
      vm.dataByTableName = {}
      vm.selectedByTableName = {}
      $scope.$destroy()
      vm.inProgress = false
      vm.initialized = false
    }

    vm.selectedByTableName = {
      pending: [],
      checkedIn: [],
      checkedOut: []
    }
    await createProductionEntityItems()
    manipulateData()
    for (const tableName of tableNames) {
      const tableColumns = getProductionEntityTableColumns(tableName)
      const baseFilter = {}
      const stateName = `app.workflow.production-entity.${tableName}`

      const { columns, selectedViewId } = ViewsService.getTablesColumns(
        tableColumns,
        stateName
      )
      const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
        selectedViewId,
        _.cloneDeep(baseFilter),
        stateName
      )
      const defaultTableSetup = {
        stateName,
        ignoreParams: true,
        data: vm.dataByTableName[tableName],
        serverSide: false,
        autoSize: false,
        pageSize: 10,
        cleanBaseFilter: baseFilter,
        baseFilter: newBaseFilter,
        selectedViewId,
        columns,
        rowSelection: true,
        defaultSelectionAll: tableName !== 'checkedOut',
        selectionChanged: selectionChanged.bind(null, tableName),
        noRecords: {
          template: function (e) {
            return `<no-results include-img="false" style="width:100%"></no-results>`
          }
        }
      }
      const defaultTableToolbarSetup = {
        stateName,
        columns,
        currentColumnIds: columns.map(c => c.uniqueId),
        filters,
        selectedViewId
      }
      $scope[`${tableName}KendoGrid`] = await KendoGridHelper.GridInstance(
        defaultTableSetup,
        $scope,
        columns
      )

      $scope[`${tableName}TableToolbar`] =
        await ViewsService.GridToolBarInstance(
          defaultTableToolbarSetup,
          $scope[`${tableName}KendoGrid`],
          $scope
        )
    }
    $scope.$applyAsync()
    vm.inProgress = false
    vm.initialized = true
  }
  return vm
}
module.exports = productionEntityService
