/* global kendo _ angular */
import ProductionEntitySplitController from './split-event/split-event.controller'
import CheckInCheckOutDialogController from './check-in-check-out/check-in-check-out.dialog.controller'
/** @ngInject */
function ProductionEntityViewController (
  ResolvedProductionEntity,
  ProductionEntity,
  $mdDialog,
  $translate,
  $mdToast,
  $scope,
  ProductionEntityHelper,
  PermissionUtils,
  ProductionEntityEvent,
  ViewsService,
  KendoGridHelper,
  DateTimeFormatService,
  $rootScope,
  htmlWork,
  WorkflowSessionItem
) {
  const vm = this
  vm.productionEntity = ResolvedProductionEntity
  vm.canChangeStatus = PermissionUtils.isPermit(
    'ProductionEntity',
    'patchAttributes'
  )
  const hasSplitPermissions = PermissionUtils.isPermit(
    'ProductionEntityEvent',
    'splitEvent'
  )
  const availableEventTypes = [
    {
      value: 'PARAMETER_SAMPLE',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.PARAMETER_SAMPLE')
    },
    {
      value: 'STATUS_CHANGE',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.STATUS_CHANGE')
    },
    {
      value: 'TRIGGER_ACTION',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.TRIGGER_ACTION')
    }
  ]
  vm.getStatusTemplate = function () {
    const statusId = vm.productionEntity.statusId
    const statusObj = vm.productionEntity.statuses.find(s => s.id === statusId)
    return ProductionEntityHelper.generateStatusTemplate(
      statusObj?.name,
      statusObj?.color ? statusObj.color : '#ffffff'
    )
  }

  vm.availableStatuses = vm.productionEntity.statuses
    .filter(status => !status.deletedAt)
    .map(status => ({
      name: status.name,
      id: status.id
    }))

  vm.changeStatus = function (statusId, statusName) {
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('PRODUCTION_ENTITY.CHANGE_STATUS'))
      .textContent(
        $translate.instant('PRODUCTION_ENTITY.CHANGE_STATUS_MESSAGE', {
          name: vm.productionEntity.name,
          status: statusName
        })
      )
      .ariaLabel('Change Status')
      .ok($translate.instant('BUTTONS.OK'))
      .cancel($translate.instant('BUTTONS.CANCEL'))

    $mdDialog.show(confirm).then(() => {
      vm.loading = true
      ProductionEntity.changeStatus({
        id: vm.productionEntity.id,
        statusId
      })
        .$promise.then(() => {
          vm.loading = false
          vm.productionEntity.status = statusName
          vm.productionEntity.statusId = statusId
          $scope.kendoGrid.reloadData()
          $rootScope.showSuccessToast('Status changed successfully')
        })
        .catch(error => {
          let errorMessage = $translate.instant(
            'PRODUCTION_ENTITY.ERRORS.GENERAL_ERROR'
          )
          if (
            [
              'PRODUCTION_ENTITY_NOT_FOUND',
              'STATUS_NOT_FOUND',
              'STATUS_ALREADY_SET',
              'STATUS_DELETED'
            ].includes(error?.data?.error?.code)
          ) {
            errorMessage = $translate.instant(
              `PRODUCTION_ENTITY.ERRORS.${error?.data?.error?.code}`
            )
          }
          vm.loading = false
          console.error('Error changing status:', error)
          $rootScope.showErrorToast(errorMessage)
        })
    })
  }

  vm.splitEvent = function (eventId) {
    return $mdDialog
      .show({
        controller: ProductionEntitySplitController,
        template: require('./split-event/split-event.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: true,
        locals: {
          statuses: vm.productionEntity.statuses
        },
        resolve: {
          ResolvedProductionEntityEvent: function () {
            return ProductionEntityEvent.findById({ id: eventId }).$promise
          }
        }
      })
      .then(() => {
        $scope.kendoGrid.reloadData()
      })
  }

  $scope.checkInCheckOut = function checkInCheckOut () {
    return $mdDialog.show({
      controller: CheckInCheckOutDialogController,
      template: require('./check-in-check-out/check-in-check-out.dialog.template.html'),
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      multiple: true,
      resolve: {
        ResolvedSessions: function () {
          return ProductionEntity.getProductionEntityRelatedSessions({
            productionEntityId: vm.productionEntity.id,
            productionEntityTypeId: vm.productionEntity.productionEntityTypeId
          }).$promise
        },
        ResolvedProductionEntity: function () {
          return vm.productionEntity
        }
      }
    })
  }

  const initTable = async function initTable () {
    const tableColumns = [
      // TYPE
      {
        uniqueId: '2gf3h4j5-8957-4a4d-9745-d1e0428dc773',
        field: 'type',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.TYPE',
        values: availableEventTypes,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'text',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: availableEventTypes
                }),
                valuePrimitive: true
              })
            }
          }
        }
      },
      // START
      {
        uniqueId: 'g2h3j4k5-8957-4a4d-9745-d1e0428dc773',
        field: 'start',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.START',
        type: 'date',
        template: data => {
          return data.start === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.start, 'dateTime')
        }
      },
      // END
      {
        uniqueId: 'a2s3d4f5-8957-4a4d-9745-d1e0428dc773',
        field: 'end',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.END',
        type: 'date',
        template: data => {
          return data.end === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.end, 'dateTime')
        }
      },
      // PARAMETER NAME
      {
        uniqueId: 't2y3u4i5-8957-4a4d-9745-d1e0428dc773',
        field: 'parameterName',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_NAME'
      },
      // PARAMETER VALUE
      {
        uniqueId: 'o2p3l4k5-8957-4a4d-9745-d1e0428dc773',
        field: 'parameterValue',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_VALUE',
        template: data => {
          if (data.parameterValueString) {
            return data.parameterValueString
          } else if (data.parameterValueNumber) {
            return data.parameterValueNumber
          } else if (data.parameterValueBoolean) {
            return data.parameterValueBoolean ? 'true' : 'false'
          } else if (data.parameterValueDate) {
            return DateTimeFormatService.formatDateTime(
              data.parameterValueDate,
              'dateTime'
            )
          }
          return '--'
        }
      },
      // WORKORDERS
      {
        uniqueId: 'c3b54b6f-9c8e-4c41-9f99-9f715bb14fb8',
        field: 'workorderNumbers',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.WORKORDERS',
        trustedTemplate: data => {
          return data.workorderNumbers?.length > 0
            ? data.workorderNumbers
                ?.map(number => {
                  return `<a ui-sref="app.workorder.view({workorderNumber: '${htmlWork.htmlEncode(
                    number
                  )}'})" target="_blank">${htmlWork.htmlEncode(number)}</a>`
                })
                .join(', ')
            : '--'
        }
      },
      // SKUS
      {
        uniqueId: '5a700479-fe51-433f-9fec-3cc109f7bc8d',
        field: 'skus',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.SKUS',
        template: data => {
          return data.skus?.length > 0 ? data.skus.join(', ') : '--'
        }
      },
      // SERIALS
      {
        uniqueId: '7aecb583-4974-41dc-8b6c-37921a78348e',
        field: 'serials',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.SERIALS',
        trustedTemplate: data => {
          return data.serials?.length > 0 ? data.serials.join(', ') : '--'
        }
      },
      // ACTIONS
      {
        uniqueId: 'z2x3c4v5-8957-4a4d-9745-d1e0428dc773',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          if (
            hasSplitPermissions &&
            data.end !== null &&
            data.type === 'STATUS_CHANGE'
          ) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" 
                     data-testid="view-${htmlWork.htmlEncode(data.id)}" 
                     ng-click="vm.splitEvent('${htmlWork.htmlEncode(
                       data.id
                     )}')">
                      <md-icon md-font-icon="icon-arrow-split-vertical" class="s20"></md-icon>
                    </md-button>`
          }
          html += '</div>'
          return html
        }
      }
    ]

    const baseFilter = {
      where: { productionEntityId: vm.productionEntity.id },
      order: 'start DESC'
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'ProductionEntityEvent',
      tableColumns
    )

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ProductionEntityEvent.find,
      count: ProductionEntityEvent.count,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }
    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }
    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  initTable()

  // Set up header options
  $scope.headerOptions = {
    icon: 'icon-eye',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('PRODUCTION_ENTITY.VIEW_PAGE_TITLE', {
      name: vm.productionEntity.name
    }),
    fabButton: {},
    menu: [
      {
        content: `<md-button ng-click="checkInCheckOut()" data-testid="check-in-check-out">
              <span translate="PRODUCTION_ENTITY.CHECK_IN_CHECK_OUT"></span>
            </md-button>`
      }
    ]
  }
}

module.exports = ProductionEntityViewController
