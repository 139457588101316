import angular from 'angular'

import ShiftsController from './shifts.controller'
import ShiftTypesController from './shift-type/shift-types.controller'
import ShiftHelper from './shift-helper'

const MODULE_NAME = 'app.shift'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .controller('ShiftTypesController', ShiftTypesController)
  .controller('ShiftsController', ShiftsController)
  .factory('ShiftHelper', ShiftHelper)
  .config(config)

/** @ngInject */
function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider,
  $translateProvider
) {
  // State
  $stateProvider
    .state('app.shift', {
      abstract: true,
      url: '/shift'
    })
    .state('app.shift.shifts', {
      url: '/shifts/?date',
      params: {
        date: {
          dynamic: true,
          value: null,
          squash: true
        }
      },
      views: {
        'content@app': {
          template: require('./shifts.template.html'),
          controller: 'ShiftsController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedProductionEntities: ProductionEntity =>
          ProductionEntity.find({
            filter: {
              fields: {
                id: true,
                name: true
              }
            }
          }).$promise,
        ResolvedShiftTypes: ShiftType => ShiftType.find({}).$promise
      }
    })
    .state('app.shift.shift-types', {
      url: '/shift-types/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ShiftTypesController',
          controllerAs: 'vm'
        }
      },
      resolve: {},

      bodyClass: 'shift shift-types'
    })

  msNavigationServiceProvider.saveItem('apps.modeling.shift', {
    title: 'SHIFT.TITLE',
    icon: 'icon-chart-timeline',
    weight: 26
  })
  msNavigationServiceProvider.saveItem('apps.modeling.shift.shifts', {
    title: 'SHIFT.SHIFTS.TITLE',
    state: 'app.shift.shifts',
    weight: 1
  })
  msNavigationServiceProvider.saveItem('apps.modeling.shift.shift-types', {
    title: 'SHIFT.SHIFT_TYPES.TITLE',
    state: 'app.shift.shift-types',
    weight: 10
  })
}

export default MODULE_NAME
