import angular from 'angular'

import ProductionEntityEditController from './add-edit/add-edit-production-entity.controller'
import ProductionEntityListController from './production-entity-list.controller'
import ProductionEntityTypeListController from './types/production-entity-type-list.controller'
import ProductionEntityViewController from './view/production-entity-view.controller'
import ProductionEntityHelper from './production-entity-helper'
import OpcUaServersListController from './opc-ua-server/opc-ua-server-list.controller'
import OpcUaServerEditController from './opc-ua-server/add-edit/add-edit-opc-ua-server.controller'

const MODULE_NAME = 'app.production-entity'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .controller('ProductionEntityListController', ProductionEntityListController)
  .controller('ProductionEntityEditController', ProductionEntityEditController)
  .controller(
    'ProductionEntityTypeListController',
    ProductionEntityTypeListController
  )
  .controller('ProductionEntityViewController', ProductionEntityViewController)
  .controller('OpcUaServersListController', OpcUaServersListController)
  .controller('OpcUaServerEditController', OpcUaServerEditController)
  .factory('ProductionEntityHelper', ProductionEntityHelper)
  .config(config)

/** @ngInject */
function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider,
  $translateProvider
) {
  // State
  $stateProvider
    .state('app.production-entity', {
      abstract: true,
      url: '/production-entity'
    })
    .state('app.production-entity.list', {
      url: '/list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ProductionEntityListController',
          controllerAs: 'vm'
        }
      },
      resolve: {},

      bodyClass: 'production-entity production-entity-list'
    })
    .state('app.production-entity.create', {
      url: '/create',
      views: {
        'content@app': {
          template: require('./add-edit/add-edit-production-entity.template.html'),
          controller: 'ProductionEntityEditController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'production-entity production-entity-show',
      resolve: {
        ResolvedProductionEntity: function () {
          return null
        },
        ResolvedOpcUaServers: function (OpcUaServer) {
          return OpcUaServer.find().$promise
        }
      }
    })
    .state('app.production-entity.edit', {
      url: '/edit/?:id',
      views: {
        'content@app': {
          template: require('./add-edit/add-edit-production-entity.template.html'),
          controller: 'ProductionEntityEditController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'production-entity production-entity-show',
      resolve: {
        ResolvedProductionEntity: function (ProductionEntity, $stateParams) {
          return ProductionEntity.findById({
            id: $stateParams.id
          }).$promise
        },
        ResolvedOpcUaServers: function (OpcUaServer) {
          return OpcUaServer.find().$promise
        }
      }
    })

    .state('app.production-entity-type', {
      abstract: true,
      url: '/production-entity-type'
    })
    .state('app.production-entity-type.list', {
      url: '/list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'ProductionEntityTypeListController',
          controllerAs: 'vm'
        }
      },
      resolve: {},

      bodyClass: 'production-entity production-entity-list'
    })
    .state('app.production-entity.view', {
      url: '/view/:id',
      views: {
        'content@app': {
          template: require('./view/production-entity-view.template.html'),
          controller: 'ProductionEntityViewController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        ResolvedProductionEntity: function (ProductionEntity, $stateParams) {
          return ProductionEntity.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: ['entityType']
            }
          }).$promise
        }
      },
      bodyClass: 'production-entity production-entity-view'
    })
    .state('app.opc-ua-server', {
      abstract: true,
      url: '/opc-ua-server'
    })
    .state('app.opc-ua-server.list', {
      url: '/list/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'OpcUaServersListController',
          controllerAs: 'vm'
        }
      },
      resolve: {},

      bodyClass: 'production-entity production-entity-list'
    })
    .state('app.opc-ua-server.create', {
      url: '/create',
      views: {
        'content@app': {
          template: require('./opc-ua-server/add-edit/add-edit-opc-ua-server.template.html'),
          controller: 'OpcUaServerEditController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'production-entity production-entity-show',
      resolve: {
        ResolvedOpcUaServer: function () {
          return null
        }
      }
    })
    .state('app.opc-ua-server.edit', {
      url: '/edit/?:id',
      views: {
        'content@app': {
          template: require('./opc-ua-server/add-edit/add-edit-opc-ua-server.template.html'),
          controller: 'OpcUaServerEditController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'production-entity production-entity-show',
      resolve: {
        ResolvedOpcUaServer: function (OpcUaServer, $stateParams) {
          return OpcUaServer.findById({
            id: $stateParams.id
          }).$promise
        }
      }
    })

  msNavigationServiceProvider.saveItem('apps.modeling.production-entity', {
    title: 'PRODUCTION_ENTITY.TITLE',
    icon: 'icon-robot',
    weight: 25
  })

  msNavigationServiceProvider.saveItem('apps.modeling.production-entity.list', {
    title: 'PRODUCTION_ENTITY.PRODUCTION_ENTITIES',
    state: 'app.production-entity.list',
    weight: 10
  })

  msNavigationServiceProvider.saveItem(
    'apps.modeling.production-entity.types',
    {
      title: 'PRODUCTION_ENTITY.PRODUCTION_ENTITY_TYPE.LIST_TITLE',
      state: 'app.production-entity-type.list',
      weight: 20
    }
  )
  msNavigationServiceProvider.saveItem(
    'apps.modeling.production-entity.opc-ua-servers',
    {
      title: 'PRODUCTION_ENTITY.OPC_UA_SERVER.LIST_TITLE',
      state: 'app.opc-ua-server.list',
      weight: 30
    }
  )
}

export default MODULE_NAME
