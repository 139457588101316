/* global _ angular */
import { isNilOrEmpty } from 'app/helper'
import UUID from 'uuid'
/** @ngInject */
function ProductionEntityTriggersController (
  $scope,
  $translate,
  $mdDialog,
  VariablesUtilsService,
  WizardConditionsService,
  locals
) {
  const variableNames = locals.fields
    .filter(field => field.category === 'variables')
    .map(field => field.id)
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.save = function save () {
    $mdDialog.hide($scope.trigger)
  }

  /**
   * Open condition wizard dialog
   * @param {number} index
   */
  $scope.openConditionWizard = function openConditionWizard (index) {
    let convertedModel = {}
    if ($scope.trigger.conditions[index]) {
      convertedModel = {
        condition: $scope.trigger.conditions[index].type || 'and',
        rules: $scope.trigger.conditions[index].values
      }
    }
    $mdDialog
      .show({
        controller: 'ConditionsDialogController',
        template: require('app/common/dialog/conditions-dialog/conditions-dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          fields: locals.fields,
          categories: locals.categories,
          model: convertedModel,
          validOperators: require('../../constants/validConditionWizardOperators.js')
        },
        resolve: {},
        bindToController: true,
        fullscreen: true,
        multiple: true,
        escapeToClose: true,
        clickOutsideToClose: false
      })
      .then(
        function (model) {
          if (model?.rules.length === 0) {
            model.rules = model.rules.map(rule => {
              const field = locals.fields.find(f => f.id === rule.id)
              if (field) {
                rule.fieldId = field.fieldId
              }
              return rule
            })
          }
          if ($scope.trigger.conditions[index]) {
            $scope.trigger.conditions[index].type = model.condition
            $scope.trigger.conditions[index].values = model.rules
            $scope.triggerConditionStrings[index] =
              WizardConditionsService.createConditionHTML(
                locals.fields,
                $scope.trigger.conditions[index]
              )
          } else {
            const obj = {
              type: model.condition,
              values: model.rules
            }
            $scope.trigger.conditions.push(obj)
            $scope.triggerConditionStrings.push(
              WizardConditionsService.createConditionHTML(locals.fields, obj)
            )
          }
        },
        function () {}
      )
  }

  const getVariables = function getVariables (isHTML = false) {
    const mergedFields = _.cloneDeep(locals.mergedTagFields)
    const parameters = VariablesUtilsService.getProductionEntityParameters(
      mergedFields,
      isHTML
    )
    if (isHTML) {
      return VariablesUtilsService.getVariablesForTinyMCE(
        'ProductionEntity',
        parameters,
        isHTML
      )
    } else {
      return VariablesUtilsService.getMergedTagsVariables(
        'ProductionEntity',
        false,
        parameters
      )
    }
  }

  /**
   * Init trigger action model according to selected principleType
   * @param {object} action
   */
  const actionSelected = function actionSelected (action) {
    action.principleId = ''
    if (action.principleType === 'sendEmail') {
      action.value = {}
    } else {
      action.value = ''
    }
  }

  const actionList = [
    // CHANGE_STATUS
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ACTIONS.CHANGE_STATUS'
      ),
      id: 'changeStatus',
      icon: 'icon-text-box-edit-outline'
    },
    // SEND_EMAIL
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ACTIONS.SEND_EMAIL'
      ),
      id: 'sendEmail',
      icon: 'icon-email'
    },
    // SEND_SMS
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ACTIONS.SEND_SMS'
      ),
      id: 'sendSMS',
      icon: 'icon-cellphone-message'
    },
    // SEND_WHATSAPP
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ACTIONS.SEND_WHATSAPP'
      ),
      id: 'sendWhatsApp',
      icon: 'icon-whatsapp'
    }
  ]

  const triggerFormlyFields = [
    {
      key: 'actions',
      type: 'repeatingTemplate',
      templateOptions: {
        layout: 'column',
        title: $translate.instant('PRODUCTION_ENTITY.TRIGGERS_DIALOG.THEN'),
        btnText: $translate.instant(
          'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ADD_ACTION'
        ),
        hideMovementButtons: true,
        addParameters: () => ({
          principleType: '',
          principleId: ''
        }),
        fields: [
          {
            className: 'display-flex layout-row',
            fieldGroup: [
              // Principle Type
              {
                key: 'principleType',
                type: 'selectWithSearch',
                className: 'flex-50 margin-5',
                templateOptions: {
                  label: $translate.instant(
                    'PRODUCTION_ENTITY.TRIGGERS_DIALOG.ACTION'
                  ),
                  staticLabel: true,
                  options: actionList,
                  onChange: function (value, options, model) {
                    actionSelected(model)
                  }
                }
              },
              // Principle Id - Multiple - true
              {
                key: 'principleId',
                type: 'selectUser',
                className: 'flex-50 margin-5 multiple-select-class',
                templateOptions: {
                  label: $translate.instant('WF.USERS'),
                  required: true,
                  multiple: true,
                  staticLabel: true,
                  showConstants: false,
                  showAssignee: false,
                  customFilter: { phone: { neq: null } },
                  onChange: function (value, options) {}
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !scope.model.principleType ||
                    scope.model.principleType === '' ||
                    (scope.model.principleType !== 'sendSMS' &&
                      scope.model.principleType !== 'sendWhatsApp')
                  )
                }
              },
              // Principle Id - Multiple - true
              {
                key: 'principleId',
                type: 'selectUser',
                className: 'flex-50 margin-5 multiple-select-class',
                templateOptions: {
                  label: $translate.instant('WF.USERS'),
                  required: true,
                  multiple: true,
                  staticLabel: true,
                  showAssignee: true,
                  showConstants: false,
                  customFilter: { email: { neq: '' } },
                  onChange: function (value, options) {}
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !scope.model.principleType ||
                    scope.model.principleType === '' ||
                    scope.model.principleType !== 'sendEmail'
                  )
                }
              },
              // Principle Id - Multiple - true
              {
                key: 'principleId',
                type: 'selectWithSearch',
                className: 'flex-50 margin-5 multiple-select-class',

                templateOptions: {
                  label: $translate.instant(
                    'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.status'
                  ),
                  required: true,
                  staticLabel: true,
                  options: locals.statuses.map(status => ({
                    id: status.id,
                    name: status.name
                  })),
                  onChange: function (value, options) {}
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !scope.model.principleType ||
                    scope.model.principleType === '' ||
                    scope.model.principleType !== 'changeStatus'
                  )
                }
              }
            ]
          },
          {
            className: 'display-flex layout-row',
            fieldGroup: [
              // SMS Content - sendSMS
              {
                key: 'value',
                type: 'variableTextarea',
                className: 'flex margin-5',
                templateOptions: {
                  required: true,
                  rows: 3,
                  grow: true,
                  showVariables: true,
                  variables: () => getVariables(false),
                  label: ''
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !scope.model.principleType ||
                    (scope.model.principleType !== 'sendSMS' &&
                      scope.model.principleType !== 'sendWhatsApp')
                  )
                },
                expressionProperties: {
                  'templateOptions.label': ($viewValue, $modelValue, scope) => {
                    switch (scope.model.principleType) {
                      case 'sendSMS':
                        return $translate.instant(
                          'PRODUCTION_ENTITY.TRIGGERS_DIALOG.SMS_CONTENT'
                        )
                      case 'sendWhatsApp':
                        return $translate.instant(
                          'PRODUCTION_ENTITY.TRIGGERS_DIALOG.WHATSAPP_CONTENT'
                        )
                      default:
                        return ''
                    }
                  },
                  'templateOptions.showVariables': (
                    $viewValue,
                    $modelValue,
                    scope
                  ) => {
                    return (
                      scope.model.principleType === 'sendSMS' ||
                      scope.model.principleType === 'sendWhatsApp'
                    )
                  }
                }
              },
              // Email title - sendEmail
              {
                key: 'value.title',
                type: 'variableInput',
                className: 'flex margin-5',
                templateOptions: {
                  type: 'text',
                  required: true,
                  showVariables: true,
                  variables: () => getVariables(false),
                  label: $translate.instant(
                    'PRODUCTION_ENTITY.TRIGGERS_DIALOG.EMAIL_TITLE'
                  )
                },
                hideExpression: function ($viewValue, $modelValue, scope) {
                  return (
                    !scope.model.principleType ||
                    scope.model.principleType === '' ||
                    scope.model.principleType !== 'sendEmail'
                  )
                }
              }
            ]
          },
          // Send Email Content
          {
            key: 'value.content',
            type: 'tinymce',
            className: 'flex-50 margin-5 pb-32',
            templateOptions: {
              tinymceOptions: {
                variable: [
                  {
                    getConfig: function () {
                      return {
                        name: 'Variables',
                        ...getVariables(true),
                        prefix: '{{',
                        suffix: '}}',
                        class: 'variable'
                      }
                    }
                  }
                ]
              }
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              return (
                !scope.model.principleType ||
                scope.model.principleType === '' ||
                scope.model.principleType !== 'sendEmail'
              )
            }
          }
        ]
      }
    }
  ]

  /**
   * check that trigger is ready for save
   */
  $scope.saveButtonIsDisabled = function saveButtonIsDisabled () {
    let disabled = false
    if (isNilOrEmpty($scope.trigger.conditions)) {
      disabled = true
    }

    for (let i = 0; i < $scope.trigger.conditions.length && !disabled; i++) {
      const condition = $scope.trigger.conditions[i]
      if (isNilOrEmpty(condition.type)) {
        disabled = true
        break
      }
      for (let j = 0; j < condition.values.length; j++) {
        const conditionValue = condition.values[j]
        if (
          isNilOrEmpty(conditionValue.field) ||
          isNilOrEmpty(conditionValue.operator)
        ) {
          disabled = true
          break
        }
        const conditionFieldExists = locals.fields.some(field => {
          return (
            field.fieldId === conditionValue.field ||
            variableNames.includes(conditionValue.field)
          )
        })
        if (conditionFieldExists) {
          if (conditionValue.value) {
            const valueIsFieldOrVariableRegex = new RegExp( // eslint-disable-line
              '^variable_|_nextplus_separator_'
            )
            const valueIsFieldOrVariable = valueIsFieldOrVariableRegex.test(
              conditionValue.value
            )
            if (valueIsFieldOrVariable) {
              const conditionValueExists = locals.fields.some(field => {
                return (
                  field.fieldId === conditionValue.value ||
                  variableNames.includes(conditionValue.value)
                )
              })
              if (!conditionValueExists) {
                disabled = true
                break
              }
            }
          }
        } else {
          disabled = true
          break
        }
      }
      if (isNilOrEmpty(condition.actions) || isNilOrEmpty(condition.values)) {
        disabled = true
        break
      }
      for (let j = 0; j < condition.actions.length && !disabled; j++) {
        const conditionAction = condition.actions[j]
        if (isNilOrEmpty(conditionAction.principleType)) {
          disabled = true
          break
        }
        switch (conditionAction.principleType) {
          case 'sendSMS':
          case 'sendWhatsApp':
            if (
              isNilOrEmpty(conditionAction.value) ||
              isNilOrEmpty(conditionAction.principleId)
            ) {
              disabled = true
            }
            break
          case 'sendEmail':
            if (
              isNilOrEmpty(conditionAction.value) ||
              isNilOrEmpty(conditionAction.principleId) ||
              isNilOrEmpty(conditionAction.value.title) ||
              isNilOrEmpty(conditionAction.value.content)
            ) {
              disabled = true
            }
            break
          case 'changeStatus':
            if (isNilOrEmpty(conditionAction.principleId)) {
              disabled = true
            }
            break
        }
      }
    }

    return disabled
  }

  const initDialog = function initDialog () {
    $scope.triggerFields = []
    if (locals.trigger) {
      $scope.trigger = locals.trigger
      $scope.triggerConditionStrings = []
      $scope.trigger.conditions.forEach(condition => {
        $scope.triggerConditionStrings.push(
          WizardConditionsService.createConditionHTML(locals.fields, condition)
        )
        $scope.triggerFields.push(triggerFormlyFields)
      })
    } else {
      $scope.triggerConditionStrings = []
      $scope.trigger = {
        id: UUID(),
        name: 'Unnamed Trigger',
        conditions: [
          {
            id: UUID(),
            type: 'and',
            values: [],
            actions: []
          }
        ]
      }
      $scope.triggerFields.push(triggerFormlyFields) // add first formly
    }
  }

  initDialog()
}

module.exports = ProductionEntityTriggersController
